import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  Text,
  Heading,
  Button,
  Box,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"
import HeroLeft from "../../components/hero-left"

const styles = {
  root: {
    width: "100%",
    height: "auto",
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mb: "32px",
    width: { base: "100%", md: "100%", lg: "50%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "24px", md: "24px", lg: "48px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },

  hero: {
    "inner-box": {
      width: { base: "100%", md: "100%", lg: "1112px" },
      justifyContent: {
        base: "flex-start",
        md: "flex-start",
        lg: "space-between",
      },
      alignItems: { base: "center", md: "center", lg: "flex-start" },
      flexDirection: { base: "column", md: "column", lg: "row" },
    },
  },

  "title-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  "content-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
    ml: { base: "0px", md: "0px", lg: "140px" },
  },

  "content-box-border": {
    flexDirection: "column",
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
    border: { base: "1px solid #DCE0E5", md: "1px solid #DCE0E5", lg: "none" },
    borderRadius: { base: "8px", md: "8px", lg: "none" },
    padding: { base: "35px", md: "35px", lg: "0px" },
    mt: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  uo: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
  },

  whitespace: {
    pb: "24px",
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigteToLearnMoreAboutERAS = () =>
  navigate("/learn-more-about-eras-quality-and-outcomes-optimization/")
const SectionStreamlined = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>ERAS Streamlined with Avant-garde</Heading>
      </Flex>
      <HeroLeft
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/eras/eras-streamlined-a.png"
            alt="Illustration of a doctor video chatting with a mother and their child in front of a blue background"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box"]}>
          <Text sx={styles.text}>
            Efficiently summarize, visualize and distribute your ERAS metrics
            with the Avant-garde platform:
          </Text>
          <Text sx={styles.whitespace}></Text>
          <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
            <UnorderedList>
              <ListItem>Automate extraction of ERAS data points</ListItem>
              <ListItem>
                Automate scrubbing of extracted data to flag potential errors
              </ListItem>
              <ListItem>Visualize your findings with:</ListItem>
              <Box>
                <UnorderedList listStylePostion="inside">
                  <ListItem> Pre-op, Intra-op, & Post-op charts</ListItem>
                  <ListItem>Physician comparison reports</ListItem>
                  <ListItem> Individual physician scorecards</ListItem>
                  <ListItem> Improvement tracking over time (trends)</ListItem>
                </UnorderedList>
                <ListItem>
                  Correlate ERAS compliance to cost reduction and improved
                  patient outcomes
                </ListItem>
              </Box>
            </UnorderedList>
          </Flex>
          <Text sx={styles.whitespace}></Text>
          <Text sx={styles.text}>
            With Avant-garde you can achieve the full potential of ERAS as you
            reduce the cost of manual overhead and improve clinician engagement.
          </Text>
        </Flex>
      </HeroLeft>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "147px",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigteToLearnMoreAboutERAS}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionStreamlined
